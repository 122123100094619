<template>
    <div :class="{ hidden: !props.item }" class="w-5 h-5 flex-shrink-0">
        <LazyIconLobby :getStyles="getStyles" v-if="props?.item?.id === 1" />
        <LazyIconFavorite :getStyles="getStyles" v-if="props?.item?.id === 2" />
        <LazyIconPropmotion
            :getStyles="getStyles"
            v-if="props?.item?.id === 3"
        />
        <LazyIconProvider
            :getStyles="getStyles"
            v-if="props?.item?.id === 11"
        />
        <LazyIconVipProgram
            :getStyles="getStyles"
            v-if="props?.item?.id === 12"
        />
        <LazyIconBlog :getStyles="getStyles" v-if="props?.item?.id === 13" />
        <LazyIconAffiliate
            :getStyles="getStyles"
            v-if="props?.item?.id === 14"
        />
        <LazyIconVpnInfo
            :getStyles="getStyles"
            v-if="props?.item?.id === 23"
        />
        <LazyIconLiveSupport
            :getStyles="getStyles"
            v-if="props?.item?.id === 15"
        />
        <LazyIconPlayAgain
            :getStyles="getStyles"
            v-if="props?.item?.id === 16"
        />
        <LazyIconBiggestWin
            :getStyles="getStyles"
            v-if="props?.item?.id === 17"
        />
        <LazyIconProfile :getStyles="getStyles" v-if="props?.item?.id === 18" />
        <LazyIconMyBonus :getStyles="getStyles" v-if="props?.item?.id === 19" />
        <LazyIconWallet :getStyles="getStyles" v-if="props?.item?.id === 20" />
        <LazyIconTransaction
            :getStyles="getStyles"
            v-if="props?.item?.id === 21"
        />
        <LazyIconChallenge
            :getStyles="getStyles"
            v-if="props?.item?.id === 22"
        />
        <LazyIconTournament
            :getStyles="getStyles"
            v-if="props?.item?.id === 4"
        />
    </div>
</template>

<script setup lang="ts">
const props = defineProps(["item", "active", "default"]);
const route = useRoute();

const getStyles = computed(() => {
    if (props.item.disabled) {
        return "#5A5B5E";
    } else if (props.default) {
        return "#fff";
    } else if (route.path.includes(props.item.url) || props.active) {
        return "#FD3FCC";
    } else {
        return "#fff";
    }
});
</script>

<style scoped>
svg {
    width: 100%;
    height: 100%;
}
</style>
